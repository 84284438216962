import * as React from "react"

const ImageBlock = ( { children } ) => {
    return (
        <div className={ `image-block` }>
            { children }
        </div>
    )
}

export default ImageBlock
