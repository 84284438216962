import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import { StaticImage } from "gatsby-plugin-image"
import ImageComponent from "../components/image"
import ImageBlock from "../components/imageblock"

const GoatsPage = () => (
  <Layout template="subpage">
    <Seo
        title="Chickens at M's Mini Farm"
        description={ `At M's Mini Farm we have 4 hens and 1 rooster. They are cautiously friendly.`}/>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h1>Chickens</h1>
            <p className={ `intro-statement` }>
            We the fine feathered hens, also known as the "Charlies" are cautiously friendly. We somehow found our way to M's Mini Farm in March of 2021. We will do most anything for grubbies.
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-dark-slate text-center` }>
        <div className={ `container-full inner` }>
            <h2>Meet the Charlies & Chuck</h2>
            <p>A snapshot of life with the chickens here on M's Mini Farm</p>
            <ImageBlock>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535340/MsMiniFarm/chicken-head-on_oakvn5.jpg"
                        alt="Chicken Head On"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535340/MsMiniFarm/chickens-with-chuck-blue_b3jebj.jpg"
                        alt="Chickens meeting Chuck Blue our New Rooster"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535340/MsMiniFarm/chicken-speckles_okymbn.jpg"
                        alt="Our Small Speckly Chicken"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535340/MsMiniFarm/chicken-snow_jdeaon.jpg"
                        alt="Chicken in the Snow"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535844/MsMiniFarm/chicken-group_b6haz1.jpg"
                        alt="Chickens eating in a circle"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660535938/MsMiniFarm/chickens-scratching_aqbibr.jpg"
                        alt="Chickens scratching for grubbies"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>

            </ImageBlock>
        </div>
    </section>
  </Layout>
)

export default GoatsPage
