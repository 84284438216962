import * as React from "react"

const ImageComponent = ( { children } ) => {
    return(
        <div className={ `image` }>
            <div className={ `inner` }>
                { children }
            </div>
        </div>
    )
}

export default ImageComponent;